.App {
  text-align: center;
}

.App-logo {
  margin-top: 25vh;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html {
  width: 100% !important;
  height: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
}

#root, #ar-wrapper, a-scene, a-nft, a-entity, .MuiContainer-root, .a-canvas {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
}

#root {
  z-index: 1000;
}

html,
body {
  margin: 0;
  overflow: hidden;
}

html {
  font-family: sans-serif;
}

#loading {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 9999999;
}

#loading img {
  height: 5em;
}

#loading span{
  color: black;
  font-weight: bold;
}

.ui {
  position: fixed;

  margin: 0.5rem;

  background-color: rgba( 255,255,255,0.6 );
  border-radius: 6px;
}

.stats {
  top: 0;
  left: 0;
  z-index: 200;
  margin: 0.5rem;
  padding: 0.5rem 0.5rem 0;
}

.stats-item {
  margin: 0 0 0.5rem;

}

.stats-item-title {
  margin: 0 0 0.25rem;

  font-size: 0.75rem;
}

#stats div {
  position: relative !important;
}

.marker {
  right: 0;
  bottom: 0;
  z-index: 200;
  margin: 0.5rem;
  padding: 0.25rem 0.5rem;

  font-size: 0.75rem;
  color: inherit;
  text-decoration: none;
}

#app {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

#video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

#canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}


#arvideo {
  display: none;
}
